<template>
  <dashboard>
    <div class="detajetPorosia">
      <div
        class="container  animate__animated animate__fadeInRight"
        v-for="(order, index) in orderDetails"
        :key="index"
      >
        <div class="row">
          <div class="col col-12 col-lg-12">
            <div class="firstSection">
              <div class="number txt">
                <p>#{{ order.orderCode }}</p>
              </div>

              <div class="method txt">
                <p>
                  <img :src="order.orderMethod[0].firstIcon" alt="" />
                  {{ order.orderMethod[0].from }}
                  <i class="fas fa-long-arrow-alt-right"></i>
                  <img :src="order.orderMethod[0].secondIcon" alt="" />
                  {{ order.orderMethod[0].to }}
                </p>
              </div>
            </div>
          </div>

          <div class="col col-12 col-lg-12">
            <div class="secondSection">
              <div class="dsc">
                <div class="status txt">
                  <h1>Statusi:</h1>

                  <p v-if="order.orderStatus <= 3">Porosia e regjistruar</p>
                  <p
                    v-else-if="order.orderStatus >= 3 && order.orderStatus <= 5"
                  >
                    Porosia është në bazë
                  </p>
                  <p
                    v-else-if="order.orderStatus >= 6 && order.orderStatus <= 7"
                  >
                    Porosia është nisur
                  </p>
                  <p v-else-if="order.orderStatus == 8">
                    Porosia është pranuar nga klienti
                  </p>
                  <p v-else-if="order.orderStatus == 9">Dërgesa ka dështuar</p>
                  <p v-else-if="order.orderStatus == 10">
                    Dërgesa nuk u pranua
                  </p>
                  <p v-else-if="order.orderStatus == 11">
                    Porosia është anuluar
                  </p>
                  <p v-else-if="order.orderStatus == 12">
                    Produkti është rikthyer te dërguesi
                  </p>
                </div>
                <div class="progressDiv">
                  <div class="progress-track">
                    <ul id="progressbar">
                      <li
                        class="step0"
                        id="step1"
                        v-bind:class="{
                          active: order.orderStatus >= 1,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- Porosia u vendos -->
                      </li>
                      <li
                        class="step0 text-center"
                        id="step2"
                        v-bind:class="{
                          active: order.orderStatus >= 4,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- Në tranzit -->
                      </li>
                      <li
                        class="step0 text-center"
                        id="step3"
                        v-bind:class="{
                          active: order.orderStatus >= 6,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- <span id="three"> E nisur </span> -->
                      </li>
                      <li
                        class="step0 text-right"
                        id="step4"
                        v-bind:class="{
                          active: order.orderStatus >= 8,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- E dërguar -->
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="price txt">
                  <h1>Çmimi:</h1>
                  <p>{{ order.orderPrice }} EUR</p>
                </div>
              </div>
              <div class="qrCodediv" v-if="order.orderStatus <= 8">
                <img
                  :src="order.orderQR"
                  alt=""
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  data-backdrop="static"
                  data-keyboard="false"
                />
              </div>
            </div>
          </div>

          <!-- Buttons Collapse -->
          <div class="col col-12">
            <div class="mainThirdSection">
              <div class="row">
                <!-- Buttons -->

                <!-- First -->
                <div class="col col-12">
                  <div
                    class="thirdSection buttonsRD animate__animated animate__fadeIn"
                  >
                    <router-link
                      :to="'/vleresimi-sherbimit/' + order.orderCode"
                      v-if="order.orderStatus == 8"
                      ><button>
                        <i class="fas fa-star"></i> Vlerëso postierin
                      </button></router-link
                    >

                    <a href="#" v-if="order.orderStatus <= 3">
                      <button
                        data-toggle="modal"
                        data-target="#extraInstructions"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <i class="fas fa-inbox"></i> Instruksione për postierin
                      </button>
                    </a>

                    <button
                      v-if="order.orderStatus < 8"
                      id="addSa"
                      @click="afterModal()"
                      data-toggle="modal"
                      data-target="#exampleModalCenterInstructions"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <i class="fas fa-map-marker-alt"></i> Saktëso adresën
                    </button>
                  </div>
                </div>
                <!-- End -->
                <!-- Buttons -->
              </div>
            </div>
          </div>
          <!--End -->

          <!-- Order location -->
          <div class="col col-12" v-if="order.orderStatus < 8">
            <div class="fifthSection">
              <div class="dscInfo">
                <div class="dsc">
                  <div class="mapLocation">
                    <div id="map"></div>
                    <div class="buttonsMap">
                      <button @click="gotoLocation()" id="fixedBtn">
                        <i class="fas fa-directions"></i> Navigacioni
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End -->

          <!-- Pranuesi -->

          <div class="col col-12 col-lg-6">
            <div class="fourthSection">
              <div class="accordion" id="accordionExample">
                <div
                  class="card"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button class="btn btn-link" type="button">
                        <i class="fas fa-long-arrow-alt-down"></i> Pranuesi
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseTwo"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body animate__animated animate__fadeIn">
                      <div
                        class="derguesiDiv"
                        v-for="(acceptor, index) in order.acceptor"
                        :key="index.sender"
                      >
                        <div class="dsc name">
                          <h1>Emri dhe Mbiemri:</h1>
                          <p>{{ acceptor.name }} {{ acceptor.lastname }}</p>
                        </div>
                        <div class="dsc phone">
                          <h1>Numri kontaktues:</h1>
                          <p>{{ acceptor.phone }}</p>
                        </div>
                        <div class="dsc address">
                          <h1>Adresa:</h1>
                          <p>{{ acceptor.address }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End -->

          <!-- Derguesi -->
          <div class="col col-12 col-lg-6">
            <div class="fourthSection">
              <div class="accordion" id="accordionExample1">
                <div
                  class="card "
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link"
                        type="button"
                        v-for="(sender, index) in order.sender"
                        :key="index.sender"
                      >
                        <i class="fas fa-long-arrow-alt-down"></i> Dërguesi -
                        {{ sender.company }}
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample1"
                  >
                    <div class="card-body animate__animated animate__fadeIn">
                      <div
                        class="derguesiDiv"
                        v-for="(sender, index) in order.sender"
                        :key="index.sender"
                      >
                        <div class="dsc name">
                          <h1>Emri dhe Mbiemri:</h1>
                          <p>{{ sender.name }} {{ sender.lastname }}</p>
                        </div>
                        <div class="dsc phone">
                          <h1>Numri kontaktues:</h1>
                          <p>{{ sender.phone }}</p>
                        </div>
                        <div class="dsc address">
                          <h1>E-maili:</h1>
                          <p>{{ sender.email }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End -->
        </div>
      </div>

      <div class="container scontainer animate__animated animate__fadeInRight">
        <div class="fifthSection">
          <div class="instructionsDiv">
            <div class="dsc">
              <h1>Shënime shtesë:</h1>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                pariatur atque molestiae possimus tempora ipsa labore maiores
                deleniti harum quod esse exercitationem, necessitatibus rem
                eligendi assumenda similique rerum amet eos!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container btnPrevious">
        <div class="sixthSection">
          <a href="./porosite.html"
            ><i class="fas fa-undo-alt"></i> Kthehu te lista e porosive</a
          >
        </div>
      </div>

      <!-- QR CODE MODAL -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="imageModal">
                <img src="../../assets/Images/qrCode.png" alt="" />
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="butoni butoni-success"
                data-dismiss="modal"
              >
                <i class="fas fa-share-alt"></i> Shpërndaje
              </button>
              <button
                type="button"
                class="butoni butoni-cancel"
                data-dismiss="modal"
              >
                <i class="fas fa-times"></i> Mbylle
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- QR CODE MODAL -->

      <!-- CHANGE COORDINATES MODAL -->
      <div
        class="modal fade"
        style="padding-right:0"
        id="exampleModalCenterInstructions"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="searchDiv">
                <input
                  type="text"
                  v-model="searchingAddress"
                  @input="searchmapMethod()"
                  placeholder="Kërko adresën ose klikoni në hartë"
                  id="searchInputfield"
                  autocomplete="off"
                />

                <select v-model="kerkomeQytet" @change="onOption(kerkomeQytet)">
                  <option value="" selected disabled hidden>
                    Selektoni komunën
                  </option>
                  <option v-for="(cities, index) in qytetet" :key="index">
                    {{ cities.cityName }}
                  </option>
                </select>
              </div>

              <div class="vleratAdresa" id="vleratAdresa">
                <div class="mainBody">
                  <ul id="listaAdresat">
                    <li
                      v-for="(search, index) in mapData.data"
                      :key="index.search"
                      @click="updateCoordinates(search.lat, search.lon)"
                    >
                      <h1>{{ search.display_name }}</h1>
                      <p v-if="toggleLatLng == true">
                        Lat: {{ search.lat }} / Long: {{ search.lon }}
                      </p>
                    </li>

                    <li v-if="thereisnodata == true">
                      <h1>
                        Të dhënat nuk u gjetën. Ju lutemi rishikoni adresën, ose
                        klikoni në hartë!
                      </h1>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="maps">
                <div id="map2"></div>
                <div class="myLocation">
                  <button @click="getMyLocation()">
                    <i class="fas fa-street-view"></i> Merre lokacionin tim
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="mapButtons">
                <div class="text">
                  <span v-if="clickedCoordsAddress">
                    <h1 style="font-weight:300;">Adresa e përditësuar:</h1>
                    <h1
                      style="color:#1478FE"
                      v-for="(clickedAddres,
                      index) in clickedCoordsAddress.data"
                      :key="index.clickedAddres"
                    >
                      {{ clickedAddres.display_name }}
                    </h1>
                  </span>
                </div>
                <div class="butonsDiv">
                  <button
                    type="button"
                    id="saveCoordinates"
                    class="butoni butoni-success"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#AgreedAddress"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-check"></i> Ruaje adresën
                  </button>
                  <button
                    type="button"
                    class="butoni butoni-cancel"
                    data-dismiss="modal"
                  >
                    <i class="fas fa-times"></i> Mos e ruaj adresën
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="AgreedAddress"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="alertDiv">
                <img src="../../assets/icon/caution-icon.png" alt="" />
                <h1>A jeni i sigurtë që kjo është adresa e saktë?</h1>
                <p>
                  Vëmendje: Nëse adresa e ndryshuar është gabim, do të aplikohet
                  kosto shtesë apo edhe të rikthehet në bazë!
                </p>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="butoni butoni-success"
                data-dismiss="modal"
              >
                <i class="fas fa-check"></i> Po jam i sigurtë
              </button>
              <button
                type="button"
                class="butoni butoni-cancel"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#exampleModalCenterInstructions"
                data-backdrop="static"
                data-keyboard="false"
              >
                <i class="fas fa-undo-alt"></i> Dua ta rishikoj përsëri
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- CHANGE COORDINATES MODAL END -->

      <!-- GIVE DRIVER INSTRUCTION MODAL -->
      <div
        class="modal fade"
        id="extraInstructions"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="extra">
                <textarea
                  name="extraInstructions"
                  id="extraInstructions"
                  cols="30"
                  rows="10"
                  placeholder="Dërgo instruksione më shumë për postierin, psh. Afër objektit të Raiffeisen Bank, kati përdhesë"
                ></textarea>
              </div>
            </div>

            <div class="modal-footer scnd">
              <button
                type="button"
                class="butoni butoni-success"
                data-dismiss="modal"
              >
                <i class="fas fa-paper-plane"></i> Dërgo
              </button>
            </div>
            <!--
                <div class="lastInstructions ">
                    <h1>Instruksionet e fundit të dërguara: </h1>
                    <div class="txt">
                        <p>- Zyrja ime gjindet afër objektit të VivaFresh Store.</p>
                        <hr>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
      <!-- GIVE DRIVER INSTRUCTION MODAL END -->
    </div>
  </dashboard>
</template>

<script>
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
import axios from "axios";
import Dashboard from "../DashHome/dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      kordinatat: [],
      driverCoords: [42.46542044192696, 20.22222379882676],
      coordsInStreets: "",
      clickedCoordsAddress: "",
      positionMarker: "",
      positionMarkerOnUpdate: "",
      positionMarkerDriver: "",
      markerIcon: "",
      mymapPosition: "",
      driverMarkerIcon: "",
      polyline: "",
      mymap: "",
      streetSearch: "",
      mapData: "",
      kerkomeQytet: "",
      thereisnodata: false,
      toggleLatLng: false,
      qytetet: [
        {
          cityName: "Artanë",
          cityLat: "42.608808320386984",
          cityLon: "21.440654734077196",
        },
        {
          cityName: "Besianë",
          cityLat: "42.91080994271356",
          cityLon: "21.195373293357928",
        },
        {
          cityName: "Burim",
          cityLat: "42.14430578725532",
          cityLon: "21.293812225356575",
        },
        {
          cityName: "Deçan",
          cityLat: "42.54031788132805",
          cityLon: "20.288321829739363",
        },
        {
          cityName: "Dragash",
          cityLat: "42.06152149231761",
          cityLon: "20.651109095931172",
        },
        {
          cityName: "Drenas",
          cityLat: "42.62108987508032",
          cityLon: "20.890442162610317",
        },
        {
          cityName: "Ferizaj",
          cityLat: "42.37020371367143",
          cityLon: "21.14826768159554",
        },
        {
          cityName: "Fushë Kosovë",
          cityLat: "42.63774588671655",
          cityLon: "21.09351065371292",
        },
        {
          cityName: "Gjakovë",
          cityLat: "42.384207675395444",
          cityLon: "20.427869992011132",
        },
        {
          cityName: "Gjilan",
          cityLat: "42.464002244978275",
          cityLon: "21.46955794185106",
        },
        {
          cityName: "Kastriot",
          cityLat: "42.6872386765646",
          cityLon: "21.066003739136963",
        },
        {
          cityName: "Kaçanik",
          cityLat: "42.22792707155417",
          cityLon: "21.256895528195958",
        },
        {
          cityName: "Klinë",
          cityLat: "42.6193871262688",
          cityLon: "20.57816485658724",
        },
        {
          cityName: "Leposaviq",
          cityLat: "43.101516817094755",
          cityLon: "20.80078994823001",
        },
        {
          cityName: "Lipjan",
          cityLat: "42.52593643422136",
          cityLon: "21.122854693130666",
        },
        {
          cityName: "Malishevë",
          cityLat: "42.48402728507205",
          cityLon: "20.742227955190625",
        },
        {
          cityName: "Pejë",
          cityLat: "42.66003677989915",
          cityLon: "20.28724565875144",
        },
        {
          cityName: "Prishtinë",
          cityLat: "42.66296595391157",
          cityLon: "21.165309630126913",
        },
        {
          cityName: "Prizren",
          cityLat: "42.217198123497155",
          cityLon: "20.743531861722637",
        },
        {
          cityName: "Rahovec",
          cityLat: "42.39982562180812",
          cityLon: "20.652943209868603",
        },
        {
          cityName: "Skenderaj",
          cityLat: "42.74761496535727",
          cityLon: "20.788539625398723",
        },
        {
          cityName: "Shtërpcë",
          cityLat: "42.24090624831328",
          cityLon: "21.02581499446747",
        },
        {
          cityName: "Shtime",
          cityLat: "42.43285135123636",
          cityLon: "21.040058401303362",
        },
        {
          cityName: "Therandë",
          cityLat: "42.362430157384225",
          cityLon: "20.83187998859898",
        },
        {
          cityName: "Viti",
          cityLat: "42.32215061148272",
          cityLon: "21.359017025791054",
        },
        {
          cityName: "Vushtrri",
          cityLat: "42.82669754898643",
          cityLon: "20.97032525976591",
        },
        {
          cityName: "Zubin Potok",
          cityLat: "42.914832128392504",
          cityLon: "20.689747385650477",
        },
        {
          cityName: "Zveçan",
          cityLat: "42.91076628023563",
          cityLon: "20.838523844889384",
        },
      ],
      info: "",
      searchingAddress: "",
      orderDetails: [
        {
          orderMethod: [
            {
              from: "Shitore",
              to: "Shtëpi",
              firstIcon: require("../../assets/icon/store-icon.png"),
              secondIcon: require("../../assets/icon/home-icon.png"),
              code: 1,
            },
          ],
          orderCode: "NSH134399903003",
          orderQR: require("../../assets/Images/qrCode.png"),
          orderStatus: 3,
          orderPrice: 24.5,
          orderContact: "+383 45 947 425",
          orderCoord: [42.460090740713504, 21.049783229827884],
          orderDesc:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime pariatur",
          sender: [
            {
              company: "Online Shop",
              name: "Filan",
              lastname: "Fisteku",
              phone: "+383 45 123 456",
              email: "filan@npo.st",
            },
          ],
          acceptor: [
            {
              name: "Filan",
              lastname: "Fisteku",
              phone: "+383 49 123 456",
              address: "Prishtine, Isak Lutolli",
            },
          ],
        },
      ],
    };
  },
  methods: {
    // Update coords on map click
    updateCoordinates: function(lat, lng) {
      var ref = this;
      if (this.mapData.data) {
        this.mapData = "";
      }
      var L = window.L;
      this.kordinatat = [lat, lng];
      this.positionMarker.setLatLng([lat, lng]).update();
      this.positionMarkerOnUpdate.setLatLng([lat, lng]).update();
      this.mymapPosition.panTo(new L.LatLng(lat, lng));
      this.mymap.panTo(new L.LatLng(lat, lng));
      ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
      ref.clickedAddress(lat, lng);
      console.log("triggered");
    },
    // End

    // On Option Selected city
    onOption: function(value) {
      for (var i = 0; i < this.qytetet.length; i++) {
        if (this.qytetet[i].cityName == value) {
          this.updateCoordinates(
            this.qytetet[i].cityLat,
            this.qytetet[i].cityLon
          );
          break;
        }
      }
    },
    // End

    // Validate map on modal click
    afterModal: function() {
      var ref = this;
      setTimeout(() => {
        this.mymapPosition.invalidateSize();
        this.mymapPosition.on("click", function(e) {
          ref.inWebLoad();
          var lat = e.latlng.lat;
          var lng = e.latlng.lng;
          console.log("Latituda : " + lat + " Longituda: " + lng);
          ref.updateCoordinates(lat, lng);
          ref.clickedAddress(lat, lng);
        });
      }, 300);
    },
    // End

    // Get to google maps route
    gotoLocation: function() {
      var ref = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(ref.yourPosition);
      } else {
        alert("GPS nuk suportohet nga shfletuesi juaj!");
      }
    },
    yourPosition: function(vlera) {
      // window.open = 'https://nominatim.openstreetmap.org/ui/reverse.html?format=html&lon=' + Newvlera + '&zoom=18', '_blank';
      jQuery(
        '<a href="https://www.google.com/maps?saddr=' +
          vlera.coords.latitude +
          "," +
          vlera.coords.longitude +
          "&daddr=" +
          this.kordinatat +
          '" target="blank"></a>'
      )[0].click();
    },
    // End

    // Function to get my coordinates
    getMyLocation: function() {
      var ref = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(ref.getmyCoordinates);
      } else {
        alert("GPS nuk suportohet nga shfletuesi juaj!");
      }
    },
    getmyCoordinates: function(myCoords) {
      var ref = this;
      if (this.mapData.data) {
        this.mapData = "";
      }
      var L = window.L;
      this.kordinatat = [myCoords.coords.latitude, myCoords.coords.longitude];
      this.positionMarker
        .setLatLng([myCoords.coords.latitude, myCoords.coords.longitude])
        .update();
      this.positionMarkerOnUpdate
        .setLatLng([myCoords.coords.latitude, myCoords.coords.longitude])
        .update();
      this.mymapPosition.panTo(
        new L.LatLng(myCoords.coords.latitude, myCoords.coords.longitude)
      );
      this.mymap.panTo(
        new L.LatLng(myCoords.coords.latitude, myCoords.coords.longitude)
      );
      ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
      ref.clickedAddress(myCoords.coords.latitude, myCoords.coords.longitude);

      console.log("Kordinatat tua");
    },
    // End

    // Search the map API
    searchmapMethod: function() {
      var self = this;
      this.thereisnodata = false;
      this.toggleLatLng = false;
      if (self.timer) clearTimeout(self.timer);

      this.mapData = {
        data: [
          {
            display_name: "Të dhënat po kërkohen! Ju lutem prisni...",
          },
        ],
      };

      self.timer = setTimeout(() => {
        axios
          .get(
            "https://osm.cargosmart.cloud/search.php?&limit=10&countrycodes=XK&city=" +
              this.kerkomeQytet +
              "&street=" +
              this.searchingAddress +
              "&viewbox=&format=json"
          )
          .then((response) => this.sortData(response))
          .catch((error) => console.log(error));
      }, 300);
    },

    // Get street address from Coords by clicking on Map
    clickedAddress: function(lat, lng) {
      axios
        .get(
          "https://osm.cargosmart.cloud/search.php?q=" +
            lat +
            "+" +
            lng +
            "&viewbox=&format=json"
        )
        .then((response) => (this.clickedCoordsAddress = response))
        .catch((error) => console.log(error));
    },
    // End

    sortData: function(response) {
      if (response.data.length >= 1) {
        this.mapData = response;
        this.thereisnodata = false;
        this.toggleLatLng = true;
      } else {
        this.thereisnodata = true;
        this.mapData = "";
      }
    },
    inWebLoad: function() {
      if (this.orderDetails[0].orderCoord.length > 1) {
        this.kordinatat = this.orderDetails[0].orderCoord;
      }
    },
    // End

    // Driver's Marker
    markerDriverfunc: function(values) {
      var L = window.L;
      this.positionMarkerDriver = new L.marker(values, {
        icon: this.driverMarkerIcon,
      }).addTo(this.mymap);
    },
    // End

    // Make a polyline
    polyLine: function(driverCoords, orderCoords) {
      var L = window.L;
      if (this.positionMarkerDriver) {
        var latlngs = Array();

        // Driver Coords
        latlngs.push(driverCoords.getLatLng());

        // Order Coords
        latlngs.push(orderCoords.getLatLng());

        if (this.polyline) {
          this.mymap.removeLayer(this.polyline);
        }
        this.polyline = L.polyline(latlngs, { color: "#1478FE" }).addTo(
          this.mymap
        );
        // The line creation
        // zoom the map to the line
        this.mymap.fitBounds(this.polyline.getBounds());
      }
    },
    // End
  },

  mounted() {
    var L = window.L;
    var ref = this;
    ref.inWebLoad();
    // Map for delivery order
    this.mymap = L.map("map").setView(this.kordinatat, 9);
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "ermirshabani/ckn8xld8826re17o0nyt90nkl",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          "pk.eyJ1IjoiZXJtaXJzaGFiYW5pIiwiYSI6ImNram1wajRpNzNvMjgydXNjMXd2NTU4OHAifQ.GVJgLjOQ52MRhhCHbQbSVg",
      }
    ).addTo(this.mymap);
    // Map for delivery order end

    // Map for changing the position by client end
    this.mymapPosition = L.map("map2").setView(this.kordinatat, 10);
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 20,

        id: "ermirshabani/ckn8xld8826re17o0nyt90nkl",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          "pk.eyJ1IjoiZXJtaXJzaGFiYW5pIiwiYSI6ImNram1wajRpNzNvMjgydXNjMXd2NTU4OHAifQ.GVJgLjOQ52MRhhCHbQbSVg",
      }
    ).addTo(this.mymapPosition);
    // Map for changing the position by client end

    // Icon for maker
    this.markerIcon = L.icon({
      iconUrl: require("../../assets/icon/markerOrder.png"),
      iconSize: [35, 55], // size of the icon
    });

    // Driver Marker
    this.driverMarkerIcon = L.icon({
      iconUrl: require("../../assets/icon/driverMarker.png"),
      iconSize: [35, 55], // size of the icon
    });
    // End

    // Position marker
    this.positionMarker = new L.marker(this.kordinatat, {
      icon: this.markerIcon,
    }).addTo(this.mymapPosition);

    this.positionMarkerOnUpdate = new L.marker(this.kordinatat, {
      icon: this.markerIcon,
    }).addTo(this.mymap);
    // End

    // Call function on webLoad to make polyline
    if (this.driverCoords) {
      ref.markerDriverfunc(this.driverCoords);
      ref.polyLine(this.positionMarkerDriver, this.positionMarkerOnUpdate);
    }
    // End
  },
};
</script>
